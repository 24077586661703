import React from "react";

import { supportForm } from "./support.module.scss";

import Layout from "../layouts/Layout";
import Link from "../components/Link";
import SupportBanner from "../partials/SupportBanner";
import SupportForm from "../partials/SupportForm";

import { useMediaQuery } from "../utils/hooks";

const SupportPage = props => {
  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <Layout
      pageTitle="Support | Brandcrush"
      ogTitle="Brandcrush: Support"
      ogImage="/images/seo/login-signup.jpg"
      ogDescription="Brandcrush support: An end-to-end platform for shopper marketing, retail media and b2b partnerships. Making it easier for businesses to buy and sell omnichannel media."
    >
      <SupportBanner />
      <section className={supportForm}>
        <SupportForm />
      </section>
    </Layout>
  );
};

export default SupportPage;
