import React, { useState } from "react";
import { Formik, Form } from "formik";

import {
  base,
  formContainer,
  formInput,
  formUpload,
  formTextArea,
  formSubmit,
  message,
} from "./SupportForm.module.scss";

import FormInput from "../components/FormInput";
import Button from "../components/Button";

import countries from "../utils/countries";
import { uploadFile, submitSupportForm } from "../utils/api";
import { isValidEmail } from "../utils/misc";

const initialValues = {
  firstName: "",
  email: "",
  subject: "",
  description: "",
  category: "",
  document: "",
};

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "This field is required";
  }

  if (!values.email) {
    errors.email = "This field is required";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.subject) {
    errors.subject = "This field is required";
  }

  if (!values.description) {
    errors.description = "This field is required";
  }

  if (!values.category) {
    errors.country = "This field is required";
  }

  return errors;
};

const Message = props => {
  const { heading, body } = props;

  return (
    <div className={message}>
      <h3>{heading}</h3>
      <p>{body}</p>
    </div>
  );
};

const SupportForm = props => {
  const [success, setSuccess] = useState(null);

  const toBase64 = buffer => {
    const u8 = new Uint8Array(buffer);
    return btoa(String.fromCharCode.apply(null, u8));
  }

  const onSubmit = (values, { setSubmitting }) => {
    const submitForm = values => {
      submitSupportForm(values)
        .then(() => {
          setSubmitting(false);
          setSuccess(true);
          setTimeout(() => setSuccess(null), 6000);
        })
        .catch(() => {
          setSubmitting(false);
          setSuccess(false);
          setTimeout(() => setSuccess(null), 4000);
        });
    }

    if (values.document) {
      values.document.arrayBuffer()
        .then(buffer => toBase64(buffer))
        .then(bytes => uploadFile(values.document.name, bytes))
        .then(response => submitForm({ ...values, document: response.data.url }));
    } else {
      submitForm(values);
    }
  };

  if (success === true) {
    return (
      <Message
        heading="Success!"
        body="Thank you for contacting us. A member of our team will be in touch with you."
      />
    );
  }

  if (success === false) {
    return (
      <Message
        heading="Oops, something went wrong!"
        body="Please check your details and try again."
      />
    );
  }

  return (
    <div className={base}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className={formContainer}>
            <FormInput name="firstName" label="First name" className={formInput} />
            <FormInput name="email" label="Email" className={formInput} />
            <FormInput name="subject" label="Subject" className={formInput} />
            <FormInput
              name="category"
              label="Category"
              type="select"
              options={[
                { value: "", label: "" },
                { value: "PRODUCT_ISSUE", label: "Product issue" },
                { value: "BILLING_ISSUE", label: "Billing issue" },
                { value: "FEATURE_REQUEST", label: "Feature request" },
                { value: "GENERAL_INQUIRY", label: "General inquiry" },
              ]}
              className={formInput}
            />
            <FormInput
              name="description"
              label="Description"
              type="textarea"
              className={formTextArea}
            />
            <FormInput
              name="document"
              label="File upload"
              type="file"
              changeValue={value => setFieldValue("document", value)}
              className={formUpload}
            />
            <div className={formSubmit}>
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SupportForm;
