import React from "react";

import { base } from "./SupportBanner.module.scss";

const SupportBanner = props => {
  return (
    <section className={base}>
      <h1>Contact support</h1>
      <img src="/images/cloud-support.svg" alt="" />
      <img src="/images/support-banner.svg" alt="" />
    </section>
  );
};

export default SupportBanner;
